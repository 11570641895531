/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { AddItemToFavoritesRequestBody } from '../model/addItemToFavoritesRequestBody';
import { FavoriteWrapper } from '../model/favoriteWrapper';
import { RemoveItemFromFavoritesRequestBody } from '../model/removeItemFromFavoritesRequestBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserDetailFavoritesService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Mark the item as favorite
     * 
     * @param userId The user id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addItemToFavorites(userId: number, body?: AddItemToFavoritesRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addItemToFavorites(userId: number, body?: AddItemToFavoritesRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addItemToFavorites(userId: number, body?: AddItemToFavoritesRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addItemToFavorites(userId: number, body?: AddItemToFavoritesRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addItemToFavorites.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/favorites`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return Favorites.csv File of Specified by Customer ID
     * 
     * @param userId The customer id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFavoritesAsCsv(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadFavoritesAsCsv(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadFavoritesAsCsv(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadFavoritesAsCsv(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadFavoritesAsCsv.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/favorites/download.csv`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return Favorites.xls File of Specified by Customer ID
     * 
     * @param userId The customer id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFavoritesAsXls(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadFavoritesAsXls(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadFavoritesAsXls(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadFavoritesAsXls(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadFavoritesAsXls.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/favorites/download.xls`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the favorites
     * 
     * @param userId The user id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavorites(userId: number, observe?: 'body', reportProgress?: boolean): Observable<FavoriteWrapper>;
    public getFavorites(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavoriteWrapper>>;
    public getFavorites(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavoriteWrapper>>;
    public getFavorites(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getFavorites.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FavoriteWrapper>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/favorites`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unmark the item as favorite
     * 
     * @param userId The user id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeItemFromFavorites(userId: number, body?: RemoveItemFromFavoritesRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeItemFromFavorites(userId: number, body?: RemoveItemFromFavoritesRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeItemFromFavorites(userId: number, body?: RemoveItemFromFavoritesRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeItemFromFavorites(userId: number, body?: RemoveItemFromFavoritesRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling removeItemFromFavorites.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/favorites/remove`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
