/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { CommentRequestBody } from '../model/commentRequestBody';
import { PageUser } from '../model/pageUser';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {PageUserExtended} from '../model/pageUserExtended';
import {ChangeStatusToUsersRequestBody} from '../model/changeStatusToUsersRequestBody';


@Injectable()
export class UserBrowserService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Sync recurly account for user
     *
     * @param userId The user Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUser(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateUser(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateUser(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateUser(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling activateUser.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/recurly-users/${encodeURIComponent(String(userId))}/sync`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activate User (if inchoate)
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUser_1(userId: number, body?: CommentRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateUser_1(userId: number, body?: CommentRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateUser_1(userId: number, body?: CommentRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateUser_1(userId: number, body?: CommentRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling activateUser_1.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/activate`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Close User (if open|reset)
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeUser(userId: number, body?: CommentRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public closeUser(userId: number, body?: CommentRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public closeUser(userId: number, body?: CommentRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public closeUser(userId: number, body?: CommentRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling closeUser.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/close`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return User Details by the Id
     *
     * @param userId The user Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(userId: number, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUser.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return List of Users
     *
     * @param searchQuery The search query
     * @param filterStatus The status of customer
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUsers(searchQuery?: string, filterStatus?: 'Inchoate' | 'Open' | 'Closed' | 'Locked' | 'Reset' | 'Closable', page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageUserExtended>;
    public listUsers(searchQuery?: string, filterStatus?: 'Inchoate' | 'Open' | 'Closed' | 'Locked' | 'Reset' | 'Closable', page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserExtended>>;
    public listUsers(searchQuery?: string, filterStatus?: 'Inchoate' | 'Open' | 'Closed' | 'Locked' | 'Reset' | 'Closable', page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserExtended>>;
    public listUsers(searchQuery?: string, filterStatus?: 'Inchoate' | 'Open' | 'Closed' | 'Locked' | 'Reset' | 'Closable', page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchQuery !== undefined && searchQuery !== null) {
            queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
        }
        if (filterStatus !== undefined && filterStatus !== null) {
            queryParameters = queryParameters.set('filterStatus', <any>filterStatus);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            });
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageUserExtended>(`${this.getBasePath()}/api/webtools/v1/users`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * Return Search Users
   *
   * @param searchId The search id
   * @param searchName The search name
   * @param searchEmail The search email
   * @param filterStatus The status of customer
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageUserExtended>;
  public searchUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserExtended>>;
  public searchUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserExtended>>;
  public searchUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (searchName !== undefined && searchName !== null) {
      queryParameters = queryParameters.set('searchName', <any>searchName);
    }
    if (searchEmail !== undefined && searchEmail !== null) {
      queryParameters = queryParameters.set('searchEmail', <any>searchEmail);
    }
    if (filterStatus !== undefined && filterStatus !== null) {
      queryParameters = queryParameters.set('filterStatus', <any>filterStatus);
    }
    if (fromRegistrationDate !== undefined && fromRegistrationDate !== null) {
      queryParameters = queryParameters.set('fromRegistrationDate', <any>fromRegistrationDate);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageUserExtended>(`${this.getBasePath()}/api/webtools/v1/users/search`,
      {
        params: queryParameters,
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return Filter Users
   *
   * @param searchId The search id
   * @param searchName The search name
   * @param searchEmail The search email
   * @param filterStatus The status of customer
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filterUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, toRegistrationDate?: string, fromSessionDate?: string, toSessionDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageUserExtended>;
  public filterUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, toRegistrationDate?: string, fromSessionDate?: string, toSessionDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserExtended>>;
  public filterUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, toRegistrationDate?: string, fromSessionDate?: string, toSessionDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserExtended>>;
  public filterUsers(searchId?: number, searchEmail?: string, searchName?: string, filterStatus?: 'inchoate' | 'open' | 'closed' | 'locked' | 'reset' | 'closable', fromRegistrationDate?: string, toRegistrationDate?: string, fromSessionDate?: string, toSessionDate?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (searchName !== undefined && searchName !== null) {
      queryParameters = queryParameters.set('searchName', <any>searchName);
    }
    if (searchEmail !== undefined && searchEmail !== null) {
      queryParameters = queryParameters.set('searchEmail', <any>searchEmail);
    }
    if (filterStatus !== undefined && filterStatus !== null) {
      queryParameters = queryParameters.set('filterStatus', <any>filterStatus);
    }
    if (fromRegistrationDate !== undefined && fromRegistrationDate !== null) {
      queryParameters = queryParameters.set('fromRegistrationDate', <any>fromRegistrationDate);
    }
    if (toRegistrationDate !== undefined && toRegistrationDate !== null) {
      queryParameters = queryParameters.set('toRegistrationDate', <any>toRegistrationDate);
    }
    if (fromSessionDate !== undefined && fromSessionDate !== null) {
      queryParameters = queryParameters.set('fromSessionDate', <any>fromSessionDate);
    }
    if (toSessionDate !== undefined && toSessionDate !== null) {
      queryParameters = queryParameters.set('toSessionDate', <any>toSessionDate);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageUserExtended>(`${this.getBasePath()}/api/webtools/v1/users/filter`,
      {
        params: queryParameters,
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
     * Lock User (if open|closed|reset)
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockUser(userId: number, body?: CommentRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockUser(userId: number, body?: CommentRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockUser(userId: number, body?: CommentRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockUser(userId: number, body?: CommentRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling lockUser.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/lock`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset user (if open|closed|locked|reset)
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUser(userId: number, body?: CommentRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetUser(userId: number, body?: CommentRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetUser(userId: number, body?: CommentRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetUser(userId: number, body?: CommentRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling resetUser.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/reset`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }




  /**
   * Change status for users
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  changeStatusForUsers(body?: ChangeStatusToUsersRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
  changeStatusForUsers(body?: ChangeStatusToUsersRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  changeStatusForUsers(body?: ChangeStatusToUsersRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  changeStatusForUsers(body?: ChangeStatusToUsersRequestBody, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/actions/change-status`,
      body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
