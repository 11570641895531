import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {module as SharedModule} from './shared';
import {environment} from '../environments/environment';
import {Configuration} from './api/configuration';
import {ApiModule} from './api/api.module';
import {AuthGuard} from './login/auth.guard';
import {LatteOAuthResourceServerErrorHandler, LoginHelperService} from './login/login-helper.service';
import {OAuthModule, OAuthResourceServerErrorHandler, OAuthStorage} from 'angular-oauth2-oidc';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpInterceptorService} from './login/http-interceptor.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found/page-not-found.component';

export function apiConfig() {
  return new Configuration({
    basePath: environment.apiConfig.basePath
  });
}

@NgModule({
  declarations: [
    AppComponent, PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot(/*{
      resourceServer: {
        allowedUrls: [/!*'https://www.hos.com', *!/'http://localhost:8585/api/v1/admin'],
        sendAccessToken: true // passes the token automatically to the requests
      }
    }*/),
    ApiModule.forRoot(apiConfig)
  ],
  providers: [
    { provide: OAuthStorage, useValue: localStorage },
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    AuthGuard,
    LoginHelperService,
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: LatteOAuthResourceServerErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
