import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthErrorEvent, OAuthEvent, OAuthResourceServerErrorHandler, OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from './auth-password-flow.config';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {_throw} from 'rxjs-compat/observable/throw';
import {HttpResponse} from '@angular/common/http';

export class AppRoutesConstants {
  public static defaultLoginRoute = '/login';
  public static defaultOnLoggedInRoute = '/';
  public static defaultOnLoggedOutRoute = '/';
}

@Injectable()
export class LatteOAuthResourceServerErrorHandler implements OAuthResourceServerErrorHandler {
  handleError(err: HttpResponse<any>): Observable<any> {
    console.log('Error! ' + JSON.stringify(err));
    return _throw(err);
  }
}

@Injectable()
export class LoginHelperService {
  loggedIn$: BehaviorSubject<boolean>;
  loggedInError$ = new Subject<any>();

  // userProfile: object;

  // store the URL so we can redirect after logging in
  redirectUrl: string = null;

  constructor(private router: Router,
              private oauthService: OAuthService) {

    // Tweak config for password flow
    // This is just needed b/c this demo uses both,
    // implicit flow as well as password flow

    this.oauthService.configure(authPasswordFlowConfig);
    // this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe((e: OAuthEvent) => {
      console.log('oauth/oidc event', e);
      if (e instanceof OAuthErrorEvent && e.type === 'token_refresh_error') {
        // invalid refresh token, logout
        this.logout();
      }
    });
    // this.oauthService.loadDiscoveryDocument();

    const hasAccessToken = this.oauthService.hasValidAccessToken();
    this.loggedIn$ = new BehaviorSubject<boolean>(hasAccessToken);
  }

  login(username: string, password: string) {
    this.oauthService
    // .fetchTokenUsingPasswordFlowAndLoadUserProfile(
      .fetchTokenUsingPasswordFlow(username, password)
      .then(() => {
        console.log('successfully logged in');
        this.onLoggedInOk();
      })
      .catch(err => {
        console.error('error logging in', err);
        this.onLoggedInError(err);
      });
  }

  refreshAccessToken(): Promise<Object> {
    return this.oauthService.refreshToken();
  }

  logout() {
    this.oauthService.logOut();
    this.onLoggedOut(true);
    // default page
    this.router.navigate([AppRoutesConstants.defaultOnLoggedOutRoute]);
  }

  get access_token() {
    return this.oauthService.getAccessToken();
  }

  get access_token_expiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  get refresh_token() {
    return this.oauthService.getRefreshToken();
  }


  /*get givenName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    return claims['firstname'];
  }

  get familyName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    return claims['lastname'];
  }*/

  /*loadUserProfile(): void {
    this.oauthService.loadUserProfile().then(up => (this.userProfile = up));
  }*/

  private onLoggedInOk() {
    // TODO analytics events
    this.loggedIn$.next(true);

    // Redirects
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    } else {
      // default page
      this.router.navigate([AppRoutesConstants.defaultOnLoggedInRoute]);
    }
  }

  private onLoggedInError(err) {
    if (this.loggedIn$.value) {
      this.loggedIn$.next(false);
    }
    this.loggedInError$.next(err);
  }

  private onLoggedOut(err: any) {
    // TODO analytics events
    this.loggedIn$.next(false);
  }

  redirectToLoggedInUrl() {
    this.router.navigate([AppRoutesConstants.defaultOnLoggedInRoute]);
  }
}
