import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injector} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Configuration} from '../configuration';

export class HosBaseService {

  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  protected httpClient: HttpClient;

  constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);

    this.basePath = this.getBasePath();
  }

  protected getBasePath() {
    return environment.apiConfig.basePath;
  }

  // implement extraHeaders if needed
  public getDefaultHeaders(extraHeaders: HttpHeaders = null): HttpHeaders {
    return new HttpHeaders();
  }

  /*protected executeHttpRequest(method: string, url: string, options?): Observable<any> {
    if (options && !options.hasOwnProperty('observe')) {
      options['observe'] = 'response';
      options['responseType'] = 'json';
    }
    return this.httpClient.request(method, url, options);
  }*/

  /**
   *
   * Extends object by coping non-existing properties.
   * @param objA object to be extended
   * @param objB source object
   */
  private extendObj<T1, T2>(objA: T1, objB: T2) {
    for (const key in objB) {
      if (objB.hasOwnProperty(key)) {
        (objA as any)[key] = (objB as any)[key];
      }
    }
    return <T1 & T2>objA;
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  protected canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
