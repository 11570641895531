import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';

export const authPasswordFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  // issuer: 'http://localhost:8585',
  issuer: environment.oauth.issuer,

  // useHttpBasicAuthForPasswordFlow: false,
  // tokenEndpoint: 'http://localhost:8585/oauth/token',
  tokenEndpoint: environment.oauth.tokenEndpoint,

  requireHttps: false, //'remoteOnly',

  // URL of the SPA to redirect the user to after login
  redirectUri: null, //window.location.origin + '/index.html',

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: null,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'hos-webtools-client',

  dummyClientSecret: '894f9201-0a5b-4a3a-9d40-da33ed5c2958',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'write read',

  showDebugInformation: true,

  oidc: false,

  requestAccessToken: true
};
