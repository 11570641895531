import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { AccountSettingsService } from './api/accountSettings.service';
import { AdminChangeLogService } from './api/adminChangeLog.service';
import { AlbumService } from './api/album.service';
import { ArtistService } from './api/artist.service';
import { CdnService } from './api/cdn.service';
import { ChannelService } from './api/channel.service';
import { EmailService } from './api/email.service';
import { ErrorService } from './api/error.service';
import { FaqService } from './api/faq.service';
import { GiftCertificatesService } from './api/giftCertificates.service';
import { HelpService } from './api/help.service';
import { ImagesRepoService } from './api/imagesRepo.service';
import { MailchimpService } from './api/mailchimp.service';
import { NewsService } from './api/news.service';
import { PeopleFeedbackService } from './api/peopleFeedback.service';
import { PlansService } from './api/plans.service';
import { PlayerService } from './api/player.service';
import { PlaylistsService } from './api/playlists.service';
import { ProgramService } from './api/program.service';
import { ProgramBannerService } from './api/programBanner.service';
import { RecurlyService } from './api/recurly.service';
import { RecurlyWebhooksService } from './api/recurlyWebhooks.service';
import { SearchService } from './api/search.service';
import { SettingsService } from './api/settings.service';
import { StationsService } from './api/stations.service';
import { StreamAnalyticsService } from './api/streamAnalytics.service';
import { StreamManagerService } from './api/streamManager.service';
import { TextFragmentsService } from './api/textFragments.service';
import { TracksService } from './api/tracks.service';
import { UserBrowserService } from './api/userBrowser.service';
import { UserDetailBlockedItemsService } from './api/userDetailBlockedItems.service';
import { UserDetailDownloadDataService } from './api/userDetailDownloadData.service';
import { UserDetailFavoritesService } from './api/userDetailFavorites.service';
import { UserDetailMigrateDataService } from './api/userDetailMigrateData.service';
import { UserDetailPlaylistBrowserService } from './api/userDetailPlaylistBrowser.service';
import { UserDetailPrepaidTimeService } from './api/userDetailPrepaidTime.service';
import { UserDetailProfileService } from './api/userDetailProfile.service';
import { UserDetailSessionService } from './api/userDetailSession.service';
import { WebtoolsSettingsService } from './api/webtoolsSettings.service';
import {RoyaltyReportService} from './api/royaltyReport.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    AccountSettingsService,
    AdminChangeLogService,
    AlbumService,
    ArtistService,
    CdnService,
    ChannelService,
    EmailService,
    ErrorService,
    FaqService,
    GiftCertificatesService,
    HelpService,
    ImagesRepoService,
    MailchimpService,
    NewsService,
    PeopleFeedbackService,
    PlansService,
    PlayerService,
    PlaylistsService,
    ProgramService,
    ProgramBannerService,
    RecurlyService,
    RecurlyWebhooksService,
    SearchService,
    SettingsService,
    StationsService,
    StreamAnalyticsService,
    StreamManagerService,
    RoyaltyReportService,
    TextFragmentsService,
    TracksService,
    UserBrowserService,
    UserDetailBlockedItemsService,
    UserDetailDownloadDataService,
    UserDetailFavoritesService,
    UserDetailMigrateDataService,
    UserDetailPlaylistBrowserService,
    UserDetailPrepaidTimeService,
    UserDetailProfileService,
    UserDetailSessionService,
    WebtoolsSettingsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
