/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChangeCurrentPlanRequestBody } from '../model/changeCurrentPlanRequestBody';
import { ConfirmPasswordResetRequestBody } from '../model/confirmPasswordResetRequestBody';
import { CreateAccountRequestBody } from '../model/createAccountRequestBody';
import { FavoriteWrapper } from '../model/favoriteWrapper';
import { HosItem } from '../model/hosItem';
import { PlanPayment } from '../model/planPayment';
import { PurchaseGiftPlanRequestBody } from '../model/purchaseGiftPlanRequestBody';
import { RedeemGiftPlanRequestBody } from '../model/redeemGiftPlanRequestBody';
import { ResetPasswordRequestBody } from '../model/resetPasswordRequestBody';
import { UpdateBillingInfoRequestBody } from '../model/updateBillingInfoRequestBody';
import { UpdateContactInfoRequestBody } from '../model/updateContactInfoRequestBody';
import { UpdatePasswordRequestBody } from '../model/updatePasswordRequestBody';
import { User } from '../model/user';
import { UserPlan } from '../model/userPlan';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccountService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Cancel subscription plan
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelSubscriptionPlan(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelSubscriptionPlan(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelSubscriptionPlan(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelSubscriptionPlan(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/v1/account/plan`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change plan
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeCurrentPlan(body?: ChangeCurrentPlanRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeCurrentPlan(body?: ChangeCurrentPlanRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeCurrentPlan(body?: ChangeCurrentPlanRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeCurrentPlan(body?: ChangeCurrentPlanRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/plan`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel HOS account
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeAccount(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public closeAccount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public closeAccount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public closeAccount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/v1/account`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset password method
     * Send the password reset email (containing the reset token) to a non-logged user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmPasswordReset(body?: ConfirmPasswordResetRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public confirmPasswordReset(body?: ConfirmPasswordResetRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public confirmPasswordReset(body?: ConfirmPasswordResetRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public confirmPasswordReset(body?: ConfirmPasswordResetRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/password/reset/confirm`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create account
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAccount(body?: CreateAccountRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createAccount(body?: CreateAccountRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createAccount(body?: CreateAccountRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createAccount(body?: CreateAccountRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/account`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the blocked items
     * 
     * @param itemType The item type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBlockedItems(itemType: 'program' | 'track' | 'album', observe?: 'body', reportProgress?: boolean): Observable<Array<HosItem>>;
    public getBlockedItems(itemType: 'program' | 'track' | 'album', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HosItem>>>;
    public getBlockedItems(itemType: 'program' | 'track' | 'album', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HosItem>>>;
    public getBlockedItems(itemType: 'program' | 'track' | 'album', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (itemType === null || itemType === undefined) {
            throw new Error('Required parameter itemType was null or undefined when calling getBlockedItems.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemType !== undefined && itemType !== null) {
            queryParameters = queryParameters.set('itemType', <any>itemType);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<HosItem>>(`${this.getBasePath()}/api/v1/account/blocked-items`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View current plan
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentPlan(observe?: 'body', reportProgress?: boolean): Observable<UserPlan>;
    public getCurrentPlan(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPlan>>;
    public getCurrentPlan(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPlan>>;
    public getCurrentPlan(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserPlan>(`${this.getBasePath()}/api/v1/account/plan`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the favorites
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavoritesV2(observe?: 'body', reportProgress?: boolean): Observable<FavoriteWrapper>;
    public getFavoritesV2(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavoriteWrapper>>;
    public getFavoritesV2(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavoriteWrapper>>;
    public getFavoritesV2(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FavoriteWrapper>(`${this.getBasePath()}/api/v1/account/favorites`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return true if it&#39;s a first login
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIsFirstRun(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public getIsFirstRun(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public getIsFirstRun(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public getIsFirstRun(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.getBasePath()}/api/v1/account/first-run`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View current ppTime (in minutes)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMinutes(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getMinutes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getMinutes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getMinutes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.getBasePath()}/api/v1/account/minutes`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the payments for user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanPayments(observe?: 'body', reportProgress?: boolean): Observable<Array<PlanPayment>>;
    public getPlanPayments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PlanPayment>>>;
    public getPlanPayments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PlanPayment>>>;
    public getPlanPayments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PlanPayment>>(`${this.getBasePath()}/api/v1/account/plan-payments`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View recurly hosted login token
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecurlyHostedLoginToken(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getRecurlyHostedLoginToken(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getRecurlyHostedLoginToken(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getRecurlyHostedLoginToken(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.getBasePath()}/api/v1/account/recurly/hosted-login-token`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View user details
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetails(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUserDetails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUserDetails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUserDetails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.getBasePath()}/api/v1/account/contact-info`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Purchase gift plan
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseGiftPlan(body?: PurchaseGiftPlanRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public purchaseGiftPlan(body?: PurchaseGiftPlanRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public purchaseGiftPlan(body?: PurchaseGiftPlanRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public purchaseGiftPlan(body?: PurchaseGiftPlanRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/account/gift-plan/purchase`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Redeem a gift plan
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redeemGiftPlan(body?: RedeemGiftPlanRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public redeemGiftPlan(body?: RedeemGiftPlanRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public redeemGiftPlan(body?: RedeemGiftPlanRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public redeemGiftPlan(body?: RedeemGiftPlanRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/account/gift-plan/redeem`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset password method
     * Send the password reset email (containing the reset token) to a non-logged user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(body?: ResetPasswordRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(body?: ResetPasswordRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(body?: ResetPasswordRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(body?: ResetPasswordRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/password/reset`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set first run flag as false (deletes it)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setFirstRunDone(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setFirstRunDone(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setFirstRunDone(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setFirstRunDone(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/first-run`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync recurly account
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncRecurlyAccount(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public syncRecurlyAccount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public syncRecurlyAccount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public syncRecurlyAccount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/account/recurly/sync`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update billing info
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBillingInfo(body?: UpdateBillingInfoRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBillingInfo(body?: UpdateBillingInfoRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBillingInfo(body?: UpdateBillingInfoRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBillingInfo(body?: UpdateBillingInfoRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/account/billing-info/update`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update contact info
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContactInfo(body?: UpdateContactInfoRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateContactInfo(body?: UpdateContactInfoRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateContactInfo(body?: UpdateContactInfoRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateContactInfo(body?: UpdateContactInfoRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/contact-info`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the password
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePassword(body?: UpdatePasswordRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePassword(body?: UpdatePasswordRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePassword(body?: UpdatePasswordRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePassword(body?: UpdatePasswordRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/account/password/update`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
