/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChangeGiftRecipientEmailRequestBody } from '../model/changeGiftRecipientEmailRequestBody';
import { ChangeGiftScheduledSendDateRequestBody } from '../model/changeGiftScheduledSendDateRequestBody';
import { Gift } from '../model/gift';
import { PageGift } from '../model/pageGift';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GiftCertificatesService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Change recipient email
     *
     * @param giftId The gift Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeRecipientEmail(giftId: number, body?: ChangeGiftRecipientEmailRequestBody, observe?: 'body', reportProgress?: boolean): Observable<Gift>;
    public changeRecipientEmail(giftId: number, body?: ChangeGiftRecipientEmailRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Gift>>;
    public changeRecipientEmail(giftId: number, body?: ChangeGiftRecipientEmailRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Gift>>;
    public changeRecipientEmail(giftId: number, body?: ChangeGiftRecipientEmailRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftId === null || giftId === undefined) {
            throw new Error('Required parameter giftId was null or undefined when calling changeRecipientEmail.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Gift>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/${encodeURIComponent(String(giftId))}/change/email`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change scheduled send date
     *
     * @param giftId The gift Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeScheduledSendDate(giftId: number, body?: ChangeGiftScheduledSendDateRequestBody, observe?: 'body', reportProgress?: boolean): Observable<Gift>;
    public changeScheduledSendDate(giftId: number, body?: ChangeGiftScheduledSendDateRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Gift>>;
    public changeScheduledSendDate(giftId: number, body?: ChangeGiftScheduledSendDateRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Gift>>;
    public changeScheduledSendDate(giftId: number, body?: ChangeGiftScheduledSendDateRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftId === null || giftId === undefined) {
            throw new Error('Required parameter giftId was null or undefined when calling changeScheduledSendDate.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Gift>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/${encodeURIComponent(String(giftId))}/change/scheduled-send-date`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return GiftCertificates.csv File
     *
     * @param giftId The gift Id
     * @param gifterEmail The gifter email
     * @param recipientEmail The recipient email
     * @param giftStatus The gift status
     * @param fromCreateDate The create date from
     * @param toCreateDate The create date to
     * @param fromSendDate The send date from
     * @param toSendDate The send date to
     * @param fromRedeemDate The redeem date from
     * @param toRedeemDate The redeem date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadGiftCertificatesAsCsv(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadGiftCertificatesAsCsv(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadGiftCertificatesAsCsv(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadGiftCertificatesAsCsv(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giftId !== undefined && giftId !== null) {
            queryParameters = queryParameters.set('giftId', <any>giftId);
        }
        if (gifterEmail !== undefined && gifterEmail !== null) {
            queryParameters = queryParameters.set('gifterEmail', <any>gifterEmail);
        }
        if (recipientEmail !== undefined && recipientEmail !== null) {
            queryParameters = queryParameters.set('recipientEmail', <any>recipientEmail);
        }
        if (giftStatus !== undefined && giftStatus !== null) {
            queryParameters = queryParameters.set('giftStatus', <any>giftStatus);
        }
        if (fromCreateDate !== undefined && fromCreateDate !== null) {
            //queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate);
        }
        if (toCreateDate !== undefined && toCreateDate !== null) {
            //queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate);
        }
        if (fromSendDate !== undefined && fromSendDate !== null) {
            queryParameters = queryParameters.set('fromSendDate', <any>fromSendDate);
        }
        if (toSendDate !== undefined && toSendDate !== null) {
            queryParameters = queryParameters.set('toSendDate', <any>toSendDate);
        }
        if (fromRedeemDate !== undefined && fromRedeemDate !== null) {
            queryParameters = queryParameters.set('fromRedeemDate', <any>fromRedeemDate);
        }
        if (toRedeemDate !== undefined && toRedeemDate !== null) {
            queryParameters = queryParameters.set('toRedeemDate', <any>toRedeemDate);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return GiftCertificates.xls File
     *
     * @param giftId The gift Id
     * @param gifterEmail The gifter email
     * @param recipientEmail The recipient email
     * @param giftStatus The gift status
     * @param fromCreateDate The create date from
     * @param toCreateDate The create date to
     * @param fromSendDate The send date from
     * @param toSendDate The send date to
     * @param fromRedeemDate The redeem date from
     * @param toRedeemDate The redeem date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadGiftCertificatesAsXls(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadGiftCertificatesAsXls(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadGiftCertificatesAsXls(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadGiftCertificatesAsXls(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giftId !== undefined && giftId !== null) {
            queryParameters = queryParameters.set('giftId', <any>giftId);
        }
        if (gifterEmail !== undefined && gifterEmail !== null) {
            queryParameters = queryParameters.set('gifterEmail', <any>gifterEmail);
        }
        if (recipientEmail !== undefined && recipientEmail !== null) {
            queryParameters = queryParameters.set('recipientEmail', <any>recipientEmail);
        }
        if (giftStatus !== undefined && giftStatus !== null) {
            queryParameters = queryParameters.set('giftStatus', <any>giftStatus);
        }
        if (fromCreateDate !== undefined && fromCreateDate !== null) {
            //queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate);
        }
        if (toCreateDate !== undefined && toCreateDate !== null) {
            //queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate);
        }
        if (fromSendDate !== undefined && fromSendDate !== null) {
            queryParameters = queryParameters.set('fromSendDate', <any>fromSendDate);
        }
        if (toSendDate !== undefined && toSendDate !== null) {
            queryParameters = queryParameters.set('toSendDate', <any>toSendDate);
        }
        if (fromRedeemDate !== undefined && fromRedeemDate !== null) {
            queryParameters = queryParameters.set('fromRedeemDate', <any>fromRedeemDate);
        }
        if (toRedeemDate !== undefined && toRedeemDate !== null) {
            queryParameters = queryParameters.set('toRedeemDate', <any>toRedeemDate);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View gift certificates by webtools admin
     *
     * @param giftId The gift Id
     * @param gifterEmail The gifter email
     * @param recipientEmail The recipient email
     * @param giftStatus The gift status
     * @param fromCreateDate The create date from
     * @param toCreateDate The create date to
     * @param fromSendDate The send date from
     * @param toSendDate The send date to
     * @param fromRedeemDate The redeem date from
     * @param toRedeemDate The redeem date to
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGiftCertificates(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageGift>;
    public getGiftCertificates(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageGift>>;
    public getGiftCertificates(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageGift>>;
    public getGiftCertificates(giftId?: number, gifterEmail?: string, recipientEmail?: string, giftStatus?: 'New' | 'Paid' | 'Sent' | 'Redeemed' | 'Forced' | 'Orphaned' | 'Cancelled', fromCreateDate?: string, toCreateDate?: string, fromSendDate?: string, toSendDate?: string, fromRedeemDate?: string, toRedeemDate?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (giftId !== undefined && giftId !== null) {
            queryParameters = queryParameters.set('giftId', <any>giftId);
        }
        if (gifterEmail !== undefined && gifterEmail !== null) {
            queryParameters = queryParameters.set('gifterEmail', <any>gifterEmail);
        }
        if (recipientEmail !== undefined && recipientEmail !== null) {
            queryParameters = queryParameters.set('recipientEmail', <any>recipientEmail);
        }
        if (giftStatus !== undefined && giftStatus !== null) {
            queryParameters = queryParameters.set('giftStatus', <any>giftStatus);
        }
        if (fromCreateDate !== undefined && fromCreateDate !== null) {
            //queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('fromCreateDate', <any>fromCreateDate);
        }
        if (toCreateDate !== undefined && toCreateDate !== null) {
            //queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate.substr(0, 10));
            queryParameters = queryParameters.set('toCreateDate', <any>toCreateDate);
        }
        if (fromSendDate !== undefined && fromSendDate !== null) {
            queryParameters = queryParameters.set('fromSendDate', <any>fromSendDate);
        }
        if (toSendDate !== undefined && toSendDate !== null) {
            queryParameters = queryParameters.set('toSendDate', <any>toSendDate);
        }
        if (fromRedeemDate !== undefined && fromRedeemDate !== null) {
            queryParameters = queryParameters.set('fromRedeemDate', <any>fromRedeemDate);
        }
        if (toRedeemDate !== undefined && toRedeemDate !== null) {
            queryParameters = queryParameters.set('toRedeemDate', <any>toRedeemDate);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageGift>(`${this.getBasePath()}/api/webtools/v1/gift-certificates`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return Gift Details by the Id
     *
     * @param giftId The gift Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGiftDetails(giftId: number, observe?: 'body', reportProgress?: boolean): Observable<Gift>;
    public getGiftDetails(giftId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Gift>>;
    public getGiftDetails(giftId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Gift>>;
    public getGiftDetails(giftId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftId === null || giftId === undefined) {
            throw new Error('Required parameter giftId was null or undefined when calling getGiftDetails.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Gift>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/${encodeURIComponent(String(giftId))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send/Resend the gift message
     *
     * @param giftId The gift Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendGiftMessage(giftId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resendGiftMessage(giftId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resendGiftMessage(giftId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resendGiftMessage(giftId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftId === null || giftId === undefined) {
            throw new Error('Required parameter giftId was null or undefined when calling resendGiftMessage.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/gift-certificates/${encodeURIComponent(String(giftId))}/resend`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
