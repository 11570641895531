import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {Injectable} from '@angular/core';
import {AppRoutesConstants, LoginHelperService} from './login-helper.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
              private router: Router,
              private loginHelperService: LoginHelperService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    // const hasIdToken = this.oauthService.hasValidIdToken();
    const hasAccessToken = this.oauthService.hasValidAccessToken();

    if (/*hasIdToken && */hasAccessToken) {
      console.log('Logged in');
      return true;
    }
    console.log('NOT logged in');

    // Store the attempted URL for redirecting
    this.loginHelperService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate([AppRoutesConstants.defaultLoginRoute]);
    return false;
  }
}
