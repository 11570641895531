import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, mergeMap, switchMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LoginHelperService} from './login-helper.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private _loginHelperService: LoginHelperService = null;

  isRefreshingToken = false;
  // private _apiHelperService: ApiHelperService = null;


  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {
  }

  get loginHelperService(): LoginHelperService {
    if (this._loginHelperService == null) {
      // I have to use the injector to avoid circular dependencies
      this._loginHelperService = this.injector.get(LoginHelperService);
    }
    return this._loginHelperService;
  }

  /*get apiHelperService(): ApiHelperService {
    if (this._apiHelperService == null) {
      // I have to use the injector to avoid circular dependencies
      this._apiHelperService = this.injector.get(ApiHelperService);
    }
    return this._apiHelperService;
  }*/

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('interceptor called');
    const me = this;
    // console.log('Before the request...');

    // Adding authorization token if required
    const sessionToken = me.loginHelperService.access_token;
    req = this.addToken(req, sessionToken);

    return next.handle(req).pipe(
      catchError(error => {
        console.log('Error...');
        if (error instanceof HttpErrorResponse && me.loginHelperService.loggedIn$.value) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              console.log('Auth error...');
              return this.authError(req, next);
            default:
              return throwError(error.error);
          }
        } else {
          console.log('Standard error...', error);
          return throwError(error.error);
        }
      })
    );
  }

  authError(req: HttpRequest<any>, next: HttpHandler) {
      console.log('Auth error - try refresh token');

      const me = this;

      if (!this.isRefreshingToken) {
          console.log('Get new token');
          this.isRefreshingToken = true;

          // Reset here so that the following requests wait until the token
          // comes back from the refreshToken call.
          this.tokenSubject.next(null);

          return from(me.loginHelperService.refreshAccessToken()).pipe( // refreshAccessToken() returns a promise, can it be converted in Observable
            mergeMap(res => {
                console.log('Refresh token OK: ' + JSON.stringify(res));
                // let sessionTokenObj = me.apiHelperService.getSessionToken();
              const sessionToken = me.loginHelperService.access_token;

              // me.loginHelperService.tokenRefreshed.next(sessionToken);

                this.tokenSubject.next(sessionToken);
                // redo the call
                return next.handle(this.addToken(req, sessionToken));
            }),
            catchError(error => {
              console.log('Refreshing token error...');
              return throwError(error);
            }),
            finalize(() => {
                this.isRefreshingToken = false;
            })
          );
      } else {
          console.log('Return token subject');
          return this.tokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                  return next.handle(this.addToken(req, token));
              }));
      }
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
      // updating the header for the next call
      // console.log('addToken: ' + token);
      if (token != null) {
        return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
      } else {
        return req;
      }
  }
}
