/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChannelActivityWrapperWithChannel } from '../model/channelActivityWrapperWithChannel';
import { ChannelActivityWrapperWithChannelIdAndDate } from '../model/channelActivityWrapperWithChannelIdAndDate';
import { PageChannelActivityWrapperWithChannelIdAndDate } from '../model/pageChannelActivityWrapperWithChannelIdAndDate';
import { PageMediaLog } from '../model/pageMediaLog';
import { PageStreamTimeWrapper } from '../model/pageStreamTimeWrapper';
import { PageTrackPerformancesWrapper } from '../model/pageTrackPerformancesWrapper';
import { StreamTimeWrapper } from '../model/streamTimeWrapper';
import { StreamTimeWrapperTotals } from '../model/streamTimeWrapperTotals';
import { TrackPerformancesWrapper } from '../model/trackPerformancesWrapper';
import { TrackPerformancesWrapperTotals } from '../model/trackPerformancesWrapperTotals';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StreamAnalyticsService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Return ChannelActivitiy.csv File
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChannelActivityReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChannelActivityReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChannelActivityReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChannelActivityReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return ChannelActivitiy.xls File
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChannelActivityReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChannelActivityReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChannelActivityReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChannelActivityReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return ChannelActivitiyTotals.csv File
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChannelActivityTotalsReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChannelActivityTotalsReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChannelActivityTotalsReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChannelActivityTotalsReportAsCsv(channelId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/totals/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return ChannelActivitiyTotals.xls File
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChannelActivityTotalsReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChannelActivityTotalsReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChannelActivityTotalsReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChannelActivityTotalsReportAsXls(channelId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/totals/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return StreamTime.csv File
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadStreamTimeReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadStreamTimeReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadStreamTimeReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadStreamTimeReportAsCsv(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return StreamTime.xls File
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadStreamTimeReportAsXls(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadStreamTimeReportAsXls(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadStreamTimeReportAsXls(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadStreamTimeReportAsXls(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return StreamTimeTotals.csv File
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadStreamTimeTotalReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadStreamTimeTotalReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadStreamTimeTotalReportAsCsv(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadStreamTimeTotalReportAsCsv(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/totals/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return StreamTimeTotals.xls File
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadStreamTimeTotalReportAsXls(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadStreamTimeTotalReportAsXls(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadStreamTimeTotalReportAsXls(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadStreamTimeTotalReportAsXls(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/totals/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return TrackPerformances.csv File
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTrackPerformancesReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadTrackPerformancesReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadTrackPerformancesReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadTrackPerformancesReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return TrackPerformances.xls File
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTrackPerformancesReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadTrackPerformancesReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadTrackPerformancesReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadTrackPerformancesReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return TrackPerformancesTotals.csv File
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTrackPerformancesTotalsReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadTrackPerformancesTotalsReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadTrackPerformancesTotalsReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadTrackPerformancesTotalsReportAsCsv(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/totals/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return TrackPerformancesTotals.xls File
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTrackPerformancesTotalsReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadTrackPerformancesTotalsReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadTrackPerformancesTotalsReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadTrackPerformancesTotalsReportAsXls(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/totals/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View channel activities
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelActivities(channelId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageChannelActivityWrapperWithChannelIdAndDate>;
    public getChannelActivities(channelId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageChannelActivityWrapperWithChannelIdAndDate>>;
    public getChannelActivities(channelId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageChannelActivityWrapperWithChannelIdAndDate>>;
    public getChannelActivities(channelId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageChannelActivityWrapperWithChannelIdAndDate>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View channel activities
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param firstResult First result
     * @param maxResults Max result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelActivitiesAsList(channelId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelActivityWrapperWithChannelIdAndDate>>;
    public getChannelActivitiesAsList(channelId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelActivityWrapperWithChannelIdAndDate>>>;
    public getChannelActivitiesAsList(channelId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelActivityWrapperWithChannelIdAndDate>>>;
    public getChannelActivitiesAsList(channelId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (firstResult !== undefined && firstResult !== null) {
            queryParameters = queryParameters.set('firstResult', <any>firstResult);
        }
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChannelActivityWrapperWithChannelIdAndDate>>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/list`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View channel activities
     * 
     * @param channelId The channel Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelActivitiesTotals(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelActivityWrapperWithChannel>>;
    public getChannelActivitiesTotals(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelActivityWrapperWithChannel>>>;
    public getChannelActivitiesTotals(channelId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelActivityWrapperWithChannel>>>;
    public getChannelActivitiesTotals(channelId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChannelActivityWrapperWithChannel>>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/channel-activities/totals`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances
     * 
     * @param userId The user Id
     * @param startDate The date from
     * @param endDate The date to
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStreamActivityForUser(userId: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageMediaLog>;
    public getStreamActivityForUser(userId: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMediaLog>>;
    public getStreamActivityForUser(userId: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMediaLog>>;
    public getStreamActivityForUser(userId: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getStreamActivityForUser.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageMediaLog>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/users/${encodeURIComponent(String(userId))}/stream-activity`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStreamTime(startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageStreamTimeWrapper>;
    public getStreamTime(startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStreamTimeWrapper>>;
    public getStreamTime(startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStreamTimeWrapper>>;
    public getStreamTime(startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageStreamTimeWrapper>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View stream time as list
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param firstResult First result
     * @param maxResults Max result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStreamTimeAsList(startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<StreamTimeWrapper>>;
    public getStreamTimeAsList(startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StreamTimeWrapper>>>;
    public getStreamTimeAsList(startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StreamTimeWrapper>>>;
    public getStreamTimeAsList(startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (firstResult !== undefined && firstResult !== null) {
            queryParameters = queryParameters.set('firstResult', <any>firstResult);
        }
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StreamTimeWrapper>>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/list`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances
     * 
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStreamTimeTotals(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<StreamTimeWrapperTotals>;
    public getStreamTimeTotals(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StreamTimeWrapperTotals>>;
    public getStreamTimeTotals(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StreamTimeWrapperTotals>>;
    public getStreamTimeTotals(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StreamTimeWrapperTotals>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/stream-time/totals`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackPerformances(trackId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageTrackPerformancesWrapper>;
    public getTrackPerformances(trackId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTrackPerformancesWrapper>>;
    public getTrackPerformances(trackId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTrackPerformancesWrapper>>;
    public getTrackPerformances(trackId?: number, startDate?: Date, endDate?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTrackPerformancesWrapper>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances as list
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param firstResult First result
     * @param maxResults Max result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TrackPerformancesWrapper>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackPerformancesWrapper>>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackPerformancesWrapper>>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (firstResult !== undefined && firstResult !== null) {
            queryParameters = queryParameters.set('firstResult', <any>firstResult);
        }
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TrackPerformancesWrapper>>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/list`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances
     * 
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackPerformancesTotals(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<TrackPerformancesWrapperTotals>;
    public getTrackPerformancesTotals(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackPerformancesWrapperTotals>>;
    public getTrackPerformancesTotals(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackPerformancesWrapperTotals>>;
    public getTrackPerformancesTotals(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TrackPerformancesWrapperTotals>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/totals`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
