/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChannelActivityWrapperWithChannel } from '../model/channelActivityWrapperWithChannel';
import { ChannelActivityWrapperWithChannelIdAndDate } from '../model/channelActivityWrapperWithChannelIdAndDate';
import { PageChannelActivityWrapperWithChannelIdAndDate } from '../model/pageChannelActivityWrapperWithChannelIdAndDate';
import { PageMediaLog } from '../model/pageMediaLog';
import { PageStreamTimeWrapper } from '../model/pageStreamTimeWrapper';
import { PageTrackPerformancesWrapper } from '../model/pageTrackPerformancesWrapper';
import { StreamTimeWrapper } from '../model/streamTimeWrapper';
import { StreamTimeWrapperTotals } from '../model/streamTimeWrapperTotals';
import { TrackPerformancesWrapper } from '../model/trackPerformancesWrapper';
import { TrackPerformancesWrapperTotals } from '../model/trackPerformancesWrapperTotals';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {BMIMusicUseReportWrapper} from '../model/bMIMusicUseReportWrapper';
import {PageBMIMusicUseReportWrapper} from '../model/pageBMIMusicUseReportWrapper';
import {PageSoundExchangeMusicUseReportWrapper} from '../model/pageSoundExchangeMusicUseReportWrapper';


@Injectable()
export class RoyaltyReportService extends HosBaseService {
    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Return TrackPerformances.csv File
     *
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/royalty-reporting/report-1/download`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * View royalty-report-1
     *
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param page Results page you want to retrieve (0..N)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<TrackPerformancesWrapperTotals>;
    public getRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackPerformancesWrapperTotals>>;
    public getRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackPerformancesWrapperTotals>>;
    public getRoyaltyReport1(trackId?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTrackPerformancesWrapper>(`${this.getBasePath()}/api/webtools/v1/royalty-reporting/report-1`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View track performances as list
     *
     * @param trackId The track Id
     * @param startDate The date from
     * @param endDate The date to
     * @param firstResult First result
     * @param maxResults Max result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TrackPerformancesWrapper>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackPerformancesWrapper>>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackPerformancesWrapper>>>;
    public getTrackPerformancesAsList(trackId?: number, startDate?: Date, endDate?: Date, firstResult?: number, maxResults?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackId !== undefined && trackId !== null) {
            queryParameters = queryParameters.set('trackId', <any>trackId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (firstResult !== undefined && firstResult !== null) {
            queryParameters = queryParameters.set('firstResult', <any>firstResult);
        }
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TrackPerformancesWrapper>>(`${this.getBasePath()}/api/webtools/v1/stream-analytics/track-performances/list`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * View royalty-report-2
   *
   * @param year The year
   * @param month The month
   * @param quarter The quarter
   * @param countryGroup The country group
   * @param freeFlag The free flag (false-> paid, true-> free)
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoyaltyReport2(year?: number, month?: number, quarter?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageBMIMusicUseReportWrapper>;
  public getRoyaltyReport2(year?: number, month?: number, quarter?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBMIMusicUseReportWrapper>>;
  public getRoyaltyReport2(year?: number, month?: number, quarter?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBMIMusicUseReportWrapper>>;
  public getRoyaltyReport2(year?: number, month?: number, quarter?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (year !== undefined && year !== null) {
      queryParameters = queryParameters.set('year', <any>year);
    }
    if (month !== undefined && month !== null) {
      queryParameters = queryParameters.set('month', <any>month);
    }
    if (quarter !== undefined && quarter !== null) {
      queryParameters = queryParameters.set('quarter', <any>quarter);
    }
    if (countryGroup !== undefined && countryGroup !== null) {
      queryParameters = queryParameters.set('country-group', <any>countryGroup);
    }
    if (freeFlag !== undefined && freeFlag !== null) {
      queryParameters = queryParameters.set('free', <any>freeFlag);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageBMIMusicUseReportWrapper>(`${this.getBasePath()}/api/webtools/v1/royalty-reporting/report-2`,
      {
        params: queryParameters,
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View royalty-report-3
   *
   * @param year The year
   * @param month The month
   * @param countryGroup The country group
   * @param freeFlag The free flag (false-> paid, true-> free)
   * @param page Results page you want to retrieve (0..N)
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoyaltyReport3(year?: number, month?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageSoundExchangeMusicUseReportWrapper>;
  public getRoyaltyReport3(year?: number, month?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSoundExchangeMusicUseReportWrapper>>;
  public getRoyaltyReport3(year?: number, month?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSoundExchangeMusicUseReportWrapper>>;
  public getRoyaltyReport3(year?: number, month?: number, countryGroup?: string, freeFlag?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (year !== undefined && year !== null) {
      queryParameters = queryParameters.set('year', <any>year);
    }
    if (month !== undefined && month !== null) {
      queryParameters = queryParameters.set('month', <any>month);
    }
    if (countryGroup !== undefined && countryGroup !== null) {
      queryParameters = queryParameters.set('country-group', <any>countryGroup);
    }
    if (freeFlag !== undefined && freeFlag !== null) {
      queryParameters = queryParameters.set('free', <any>freeFlag);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageSoundExchangeMusicUseReportWrapper>(`${this.getBasePath()}/api/webtools/v1/royalty-reporting/report-3`,
      {
        params: queryParameters,
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
