/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChangeUserEmailRequestBody } from '../model/changeUserEmailRequestBody';
import { ChangeUserNameRequestBody } from '../model/changeUserNameRequestBody';
import { ChangeUserPasswordRequestBody } from '../model/changeUserPasswordRequestBody';
import { PageAdminChangeLog } from '../model/pageAdminChangeLog';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserDetailProfileService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Change user name
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeName(userId: number, body?: ChangeUserNameRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeName(userId: number, body?: ChangeUserNameRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeName(userId: number, body?: ChangeUserNameRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeName(userId: number, body?: ChangeUserNameRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling changeName.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/name`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user email
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserEmail(userId: number, body?: ChangeUserEmailRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeUserEmail(userId: number, body?: ChangeUserEmailRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeUserEmail(userId: number, body?: ChangeUserEmailRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeUserEmail(userId: number, body?: ChangeUserEmailRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling changeUserEmail.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/email`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user password
     *
     * @param userId The user Id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserPassword(userId: number, body?: ChangeUserPasswordRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeUserPassword(userId: number, body?: ChangeUserPasswordRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeUserPassword(userId: number, body?: ChangeUserPasswordRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeUserPassword(userId: number, body?: ChangeUserPasswordRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling changeUserPassword.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/password`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return change-history.csv File of Specified by Customer ID
     *
     * @param userId The user Id
     * @param type Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChangeHistoryAsCsv(userId: number, type?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChangeHistoryAsCsv(userId: number, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChangeHistoryAsCsv(userId: number, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChangeHistoryAsCsv(userId: number, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadChangeHistoryAsCsv.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/history/download.csv`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return change-history.xls File of Specified by Customer ID
     *
     * @param userId The user Id
     * @param type Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadChangeHistoryAsXls(userId: number, type?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadChangeHistoryAsXls(userId: number, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadChangeHistoryAsXls(userId: number, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadChangeHistoryAsXls(userId: number, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadChangeHistoryAsXls.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/history/download.xls`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * View change history by webtools admin
     *
     * @param userId The user Id
     * @param type Type
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viewChangeHistory(userId: number, type?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageAdminChangeLog>;
    public viewChangeHistory(userId: number, type?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAdminChangeLog>>;
    public viewChangeHistory(userId: number, type?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAdminChangeLog>>;
    public viewChangeHistory(userId: number, type?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling viewChangeHistory.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageAdminChangeLog>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/change/history`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * Sync user with recurly info
   *
   * @param userId The user Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public syncRecurly(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public syncRecurly(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public syncRecurly(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public syncRecurly(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling changeName.');
    }


    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/recurly-users/${encodeURIComponent(String(userId))}/sync`,
      null,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Resend activation email
   *
   * @param userId The user Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resendActivationEmail(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public resendActivationEmail(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public resendActivationEmail(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public resendActivationEmail(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling resendActivationEmail.');
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/resend-email/activation`,
      null,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Resend WhatYouCanDoAtHos email
   *
   * @param userId The user Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resendWhatYouCanDoEmail(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public resendWhatYouCanDoEmail(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public resendWhatYouCanDoEmail(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public resendWhatYouCanDoEmail(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling resendActivationEmail.');
    }

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/resend-email/what-you-can-do-at-hos`,
      null,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
