/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { Program } from '../model/program';
import { RatingRequestBody } from '../model/ratingRequestBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProgramService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Return the programs
     * 
     * @param pgmNoFrom Pgm Number From
     * @param pgmNoTo Pgm Number To
     * @param genreIds Genre ids
     * @param seasonId Season id
     * @param holidayId Holiday id
     * @param title Title
     * @param yearFrom Year From
     * @param yearTo Year To
     * @param orderBy The Order by field
     * @param body The Order by direction
     * @param page The current page
     * @param size The size of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public browsePrograms(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Program>>;
    public browsePrograms(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Program>>>;
    public browsePrograms(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Program>>>;
    public browsePrograms(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pgmNoFrom !== undefined && pgmNoFrom !== null) {
            queryParameters = queryParameters.set('pgmNoFrom', <any>pgmNoFrom);
        }
        if (pgmNoTo !== undefined && pgmNoTo !== null) {
            queryParameters = queryParameters.set('pgmNoTo', <any>pgmNoTo);
        }
        if (genreIds) {
            genreIds.forEach((element) => {
                queryParameters = queryParameters.append('genreIds', <any>element);
            })
        }
        if (seasonId !== undefined && seasonId !== null) {
            queryParameters = queryParameters.set('seasonId', <any>seasonId);
        }
        if (holidayId !== undefined && holidayId !== null) {
            queryParameters = queryParameters.set('holidayId', <any>holidayId);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (yearFrom !== undefined && yearFrom !== null) {
            queryParameters = queryParameters.set('yearFrom', <any>yearFrom);
        }
        if (yearTo !== undefined && yearTo !== null) {
            queryParameters = queryParameters.set('yearTo', <any>yearTo);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<Program>>(`${this.getBasePath()}/api/v1/programs/browse`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the programs
     * 
     * @param pgmNoFrom Pgm Number From
     * @param pgmNoTo Pgm Number To
     * @param genreIds Genre ids
     * @param seasonId Season id
     * @param holidayId Holiday id
     * @param title Title
     * @param yearFrom Year From
     * @param yearTo Year To
     * @param orderBy The Order by field
     * @param body The Order by direction
     * @param page The current page
     * @param size The size of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public browseProgramsOld(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Program>>;
    public browseProgramsOld(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Program>>>;
    public browseProgramsOld(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Program>>>;
    public browseProgramsOld(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: Array<number>, seasonId?: number, holidayId?: number, title?: string, yearFrom?: number, yearTo?: number, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pgmNoFrom !== undefined && pgmNoFrom !== null) {
            queryParameters = queryParameters.set('pgmNoFrom', <any>pgmNoFrom);
        }
        if (pgmNoTo !== undefined && pgmNoTo !== null) {
            queryParameters = queryParameters.set('pgmNoTo', <any>pgmNoTo);
        }
        if (genreIds) {
            genreIds.forEach((element) => {
                queryParameters = queryParameters.append('genreIds', <any>element);
            })
        }
        if (seasonId !== undefined && seasonId !== null) {
            queryParameters = queryParameters.set('seasonId', <any>seasonId);
        }
        if (holidayId !== undefined && holidayId !== null) {
            queryParameters = queryParameters.set('holidayId', <any>holidayId);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (yearFrom !== undefined && yearFrom !== null) {
            queryParameters = queryParameters.set('yearFrom', <any>yearFrom);
        }
        if (yearTo !== undefined && yearTo !== null) {
            queryParameters = queryParameters.set('yearTo', <any>yearTo);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<Program>>(`${this.getBasePath()}/api/v1/programs/browse/old`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the program detail
     * 
     * @param programId The program id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramDetail(programId: number, observe?: 'body', reportProgress?: boolean): Observable<Program>;
    public getProgramDetail(programId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Program>>;
    public getProgramDetail(programId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Program>>;
    public getProgramDetail(programId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getProgramDetail.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Program>(`${this.getBasePath()}/api/v1/programs/${encodeURIComponent(String(programId))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the programs
     * 
     * @param filterBy The filter by type
     * @param filterValue The filter by value
     * @param orderBy The Order by field
     * @param body The Order by direction
     * @param page The current page
     * @param size The size of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrograms(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Program>>;
    public getPrograms(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Program>>>;
    public getPrograms(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Program>>>;
    public getPrograms(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterBy !== undefined && filterBy !== null) {
            queryParameters = queryParameters.set('filterBy', <any>filterBy);
        }
        if (filterValue !== undefined && filterValue !== null) {
            queryParameters = queryParameters.set('filterValue', <any>filterValue);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<Program>>(`${this.getBasePath()}/api/v1/programs`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the programs
     * 
     * @param filterBy The filter by type
     * @param filterValue The filter by value
     * @param orderBy The Order by field
     * @param body The Order by direction
     * @param page The current page
     * @param size The size of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramsOld(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Program>>;
    public getProgramsOld(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Program>>>;
    public getProgramsOld(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Program>>>;
    public getProgramsOld(filterBy?: 'recent' | 'popular' | 'genre' | 'number' | 'year' | 'season', filterValue?: string, orderBy?: 'title' | 'artists' | 'label' | 'popularity', body?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterBy !== undefined && filterBy !== null) {
            queryParameters = queryParameters.set('filterBy', <any>filterBy);
        }
        if (filterValue !== undefined && filterValue !== null) {
            queryParameters = queryParameters.set('filterValue', <any>filterValue);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<Program>>(`${this.getBasePath()}/api/v1/programs/old`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return a random program
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSurpriseMeProgram(observe?: 'body', reportProgress?: boolean): Observable<Program>;
    public getSurpriseMeProgram(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Program>>;
    public getSurpriseMeProgram(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Program>>;
    public getSurpriseMeProgram(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Program>(`${this.getBasePath()}/api/v1/programs/surprise-me`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the This-Week program
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThisWeekProgram(observe?: 'body', reportProgress?: boolean): Observable<Program>;
    public getThisWeekProgram(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Program>>;
    public getThisWeekProgram(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Program>>;
    public getThisWeekProgram(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Program>(`${this.getBasePath()}/api/v1/programs/this-week`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the program as blocked
     * 
     * @param programId The program id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markBlocked(programId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public markBlocked(programId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public markBlocked(programId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public markBlocked(programId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling markBlocked.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/programs/${encodeURIComponent(String(programId))}/block`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the program as favorite
     * 
     * @param programId The program id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markFavorite(programId: number, body?: RatingRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public markFavorite(programId: number, body?: RatingRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public markFavorite(programId: number, body?: RatingRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public markFavorite(programId: number, body?: RatingRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling markFavorite.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/v1/programs/${encodeURIComponent(String(programId))}/favorite`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the program as unblocked
     * 
     * @param programId The program id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unmarkBlocked(programId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unmarkBlocked(programId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unmarkBlocked(programId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unmarkBlocked(programId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling unmarkBlocked.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/v1/programs/${encodeURIComponent(String(programId))}/block`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unmark the program as favorite
     * 
     * @param programId The program id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unmarkFavorite(programId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unmarkFavorite(programId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unmarkFavorite(programId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unmarkFavorite(programId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling unmarkFavorite.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/v1/programs/${encodeURIComponent(String(programId))}/favorite`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
