/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RecurlyService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * recurly setup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUp(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setUp(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setUp(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setUp(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/recurly/setup`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * recurly tear down
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tearDown(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public tearDown(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public tearDown(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public tearDown(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/recurly/tear-down`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * recurly testAssociateSubscription
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testAssociateSubscription(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public testAssociateSubscription(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public testAssociateSubscription(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public testAssociateSubscription(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/recurly/test-associate-subscription`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * recurly testAssociateSubscription
     * 
     * @param accountCode The account code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testCancelSubscriptions(accountCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public testCancelSubscriptions(accountCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public testCancelSubscriptions(accountCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public testCancelSubscriptions(accountCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling testCancelSubscriptions.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/v1/recurly/test-cancel-subscription/${encodeURIComponent(String(accountCode))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * recurly testGetEmptySubscriptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testGetEmptySubscriptions(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public testGetEmptySubscriptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public testGetEmptySubscriptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public testGetEmptySubscriptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.getBasePath()}/api/v1/recurly/test-get-empty-subscriptions`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * recurly testTransactions
     * 
     * @param accountCode The account code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testTransactions(accountCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public testTransactions(accountCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public testTransactions(accountCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public testTransactions(accountCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling testTransactions.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/v1/recurly/test-transactions/${encodeURIComponent(String(accountCode))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
