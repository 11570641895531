/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { AddItemToPlaylistRequestBody } from '../model/addItemToPlaylistRequestBody';
import { Playlist } from '../model/playlist';
import { PlaylistNameRequestBody } from '../model/playlistNameRequestBody';
import { RemoveItemToPlaylistRequestBody } from '../model/removeItemToPlaylistRequestBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserDetailPlaylistBrowserService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Add a playlist item to the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addItemToPlaylist(userId: number, playlistId: number, body?: AddItemToPlaylistRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addItemToPlaylist(userId: number, playlistId: number, body?: AddItemToPlaylistRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addItemToPlaylist(userId: number, playlistId: number, body?: AddItemToPlaylistRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addItemToPlaylist(userId: number, playlistId: number, body?: AddItemToPlaylistRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addItemToPlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling addItemToPlaylist.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}/add`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a playlist
     * 
     * @param userId The user id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPlaylist(userId: number, body?: PlaylistNameRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createPlaylist(userId: number, body?: PlaylistNameRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createPlaylist(userId: number, body?: PlaylistNameRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createPlaylist(userId: number, body?: PlaylistNameRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createPlaylist.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return Playlist.csv File of Specified by Customer ID
     * 
     * @param userId The customer id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPlaylistsAsCsv(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadPlaylistsAsCsv(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadPlaylistsAsCsv(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadPlaylistsAsCsv(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadPlaylistsAsCsv.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/download.csv`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return Playlist.xls File of Specified by Customer ID
     * 
     * @param userId The customer id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPlaylistsAsXls(userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadPlaylistsAsXls(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadPlaylistsAsXls(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadPlaylistsAsXls(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadPlaylistsAsXls.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/download.xls`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the playlist detail
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlaylistDetail(userId: number, playlistId: number, observe?: 'body', reportProgress?: boolean): Observable<Playlist>;
    public getPlaylistDetail(userId: number, playlistId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Playlist>>;
    public getPlaylistDetail(userId: number, playlistId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Playlist>>;
    public getPlaylistDetail(userId: number, playlistId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getPlaylistDetail.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling getPlaylistDetail.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Playlist>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the playlists
     * 
     * @param userId The user id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlaylists(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Playlist>>;
    public getPlaylists(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Playlist>>>;
    public getPlaylists(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Playlist>>>;
    public getPlaylists(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getPlaylists.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Playlist>>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishPlaylist(userId: number, playlistId: number, observe?: 'body', reportProgress?: boolean): Observable<Playlist>;
    public publishPlaylist(userId: number, playlistId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Playlist>>;
    public publishPlaylist(userId: number, playlistId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Playlist>>;
    public publishPlaylist(userId: number, playlistId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling publishPlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling publishPlaylist.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Playlist>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}/enable`,
            null,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a playlist-item from the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeItemFromPlaylist(userId: number, playlistId: number, body?: RemoveItemToPlaylistRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeItemFromPlaylist(userId: number, playlistId: number, body?: RemoveItemToPlaylistRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeItemFromPlaylist(userId: number, playlistId: number, body?: RemoveItemToPlaylistRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeItemFromPlaylist(userId: number, playlistId: number, body?: RemoveItemToPlaylistRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling removeItemFromPlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling removeItemFromPlaylist.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}/remove`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePlaylist(userId: number, playlistId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removePlaylist(userId: number, playlistId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removePlaylist(userId: number, playlistId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removePlaylist(userId: number, playlistId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling removePlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling removePlaylist.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}/disable`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rename the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renamePlaylist(userId: number, playlistId: number, body?: PlaylistNameRequestBody, observe?: 'body', reportProgress?: boolean): Observable<Playlist>;
    public renamePlaylist(userId: number, playlistId: number, body?: PlaylistNameRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Playlist>>;
    public renamePlaylist(userId: number, playlistId: number, body?: PlaylistNameRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Playlist>>;
    public renamePlaylist(userId: number, playlistId: number, body?: PlaylistNameRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling renamePlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling renamePlaylist.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Playlist>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reorder the items in the playlist
     * 
     * @param userId The user id
     * @param playlistId The playlist id
     * @param body The ordered items
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderItemsInPlaylist(userId: number, playlistId: number, body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reorderItemsInPlaylist(userId: number, playlistId: number, body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reorderItemsInPlaylist(userId: number, playlistId: number, body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reorderItemsInPlaylist(userId: number, playlistId: number, body: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling reorderItemsInPlaylist.');
        }

        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling reorderItemsInPlaylist.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reorderItemsInPlaylist.');
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.getBasePath()}/api/webtools/v1/users/${encodeURIComponent(String(userId))}/playlists/${encodeURIComponent(String(playlistId))}/sort`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
