export const environment = {
  production: true,
  debug: false,
  apiConfig: {
    basePath: 'https://webtools-staging.hos.com/a',
    completePath: 'https://webtools-staging.hos.com/a/api/v1'
  },
  basePathImageUrl: 'https://webtools-staging.hos.com/a/api/v1',
  oauth: {
    issuer: 'https://webtools-staging.hos.com/a/oauth/token',
    tokenEndpoint: 'https://webtools-staging.hos.com/a/oauth/token'
  },
  recurly: {
    basePath: 'https://hos.recurly.com',
    giftCardDetailPagePath: '/gift_cards/'
  },
  website_url: 'https://staging.hos.com'
};
