/**
 * HOS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * Contact: info@hos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Injector, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import {HosBaseService} from "./hos-base.service";

import { Observable }                                        from 'rxjs';

import { ChangeAlbumFreeRangeRequestBody } from '../model/changeAlbumFreeRangeRequestBody';
import { ChangeChannelFreeRangeRequestBody } from '../model/changeChannelFreeRangeRequestBody';
import { ChangeChannelVisibleRangeRequestBody } from '../model/changeChannelVisibleRangeRequestBody';
import { ChangeProgramFreeRangeRequestBody } from '../model/changeProgramFreeRangeRequestBody';
import { Channel } from '../model/channel';
import { PageAlbum } from '../model/pageAlbum';
import { PageChannel } from '../model/pageChannel';
import { PageProgram } from '../model/pageProgram';
import { RemoveItemAsFreeRequestBody } from '../model/removeItemAsFreeRequestBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StreamManagerService extends HosBaseService {

    // protected basePath = 'https://localhost';
    // public defaultHeaders = new HttpHeaders();
    // public configuration = new Configuration();

    /*constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }*/

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    /*private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }*/


    /**
     * Get all the channels
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannels(observe?: 'body', reportProgress?: boolean): Observable<Array<Channel>>;
    public getChannels(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Channel>>>;
    public getChannels(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Channel>>>;
    public getChannels(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Channel>>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels`,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the albums
     * 
     * @param date The date of check
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreeAlbums(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageAlbum>;
    public getFreeAlbums(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAlbum>>;
    public getFreeAlbums(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAlbum>>;
    public getFreeAlbums(date?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageAlbum>(`${this.getBasePath()}/api/webtools/v1/stream-manager/albums`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the free channels
     * 
     * @param date The date of check
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreeChannels(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageChannel>;
    public getFreeChannels(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageChannel>>;
    public getFreeChannels(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageChannel>>;
    public getFreeChannels(date?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageChannel>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels/free`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the programs
     * 
     * @param date The date of check
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreePrograms(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageProgram>;
    public getFreePrograms(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageProgram>>;
    public getFreePrograms(date?: Date, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageProgram>>;
    public getFreePrograms(date?: Date, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageProgram>(`${this.getBasePath()}/api/webtools/v1/stream-manager/programs`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the visible channels
     * 
     * @param date The date of check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVisibleChannels(date?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<Channel>>;
    public getVisibleChannels(date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Channel>>>;
    public getVisibleChannels(date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Channel>>>;
    public getVisibleChannels(date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }

        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Channel>>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels/visible`,
            {
                params: queryParameters,
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the album as free
     * 
     * @param albumId The album id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAlbumAsFree(albumId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeAlbumAsFree(albumId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeAlbumAsFree(albumId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeAlbumAsFree(albumId: number, body?: RemoveItemAsFreeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (albumId === null || albumId === undefined) {
            throw new Error('Required parameter albumId was null or undefined when calling removeAlbumAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/albums/${encodeURIComponent(String(albumId))}/free/delete`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the channel as free
     * 
     * @param channelId The channel id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeChannelAsFree(channelId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeChannelAsFree(channelId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeChannelAsFree(channelId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeChannelAsFree(channelId: number, body?: RemoveItemAsFreeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling removeChannelAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels/${encodeURIComponent(String(channelId))}/free/delete`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the program as free
     * 
     * @param programId The program id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProgramAsFree(programId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeProgramAsFree(programId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeProgramAsFree(programId: number, body?: RemoveItemAsFreeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeProgramAsFree(programId: number, body?: RemoveItemAsFreeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling removeProgramAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/programs/${encodeURIComponent(String(programId))}/free/delete`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the album as free
     * 
     * @param albumId The album id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAlbumAsFree(albumId: number, body?: ChangeAlbumFreeRangeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setAlbumAsFree(albumId: number, body?: ChangeAlbumFreeRangeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setAlbumAsFree(albumId: number, body?: ChangeAlbumFreeRangeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setAlbumAsFree(albumId: number, body?: ChangeAlbumFreeRangeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (albumId === null || albumId === undefined) {
            throw new Error('Required parameter albumId was null or undefined when calling setAlbumAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/albums/${encodeURIComponent(String(albumId))}/free`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the channel as free
     * 
     * @param channelId The channel id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChannelAsFree(channelId: number, body?: ChangeChannelFreeRangeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setChannelAsFree(channelId: number, body?: ChangeChannelFreeRangeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setChannelAsFree(channelId: number, body?: ChangeChannelFreeRangeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setChannelAsFree(channelId: number, body?: ChangeChannelFreeRangeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChannelAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels/${encodeURIComponent(String(channelId))}/free`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the channel as visible
     * 
     * @param channelId The channel id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChannelAsVisible(channelId: number, body?: ChangeChannelVisibleRangeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setChannelAsVisible(channelId: number, body?: ChangeChannelVisibleRangeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setChannelAsVisible(channelId: number, body?: ChangeChannelVisibleRangeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setChannelAsVisible(channelId: number, body?: ChangeChannelVisibleRangeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChannelAsVisible.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/channels/${encodeURIComponent(String(channelId))}/visible`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the program as free
     * 
     * @param programId The program id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProgramAsFree(programId: number, body?: ChangeProgramFreeRangeRequestBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setProgramAsFree(programId: number, body?: ChangeProgramFreeRangeRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setProgramAsFree(programId: number, body?: ChangeProgramFreeRangeRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setProgramAsFree(programId: number, body?: ChangeProgramFreeRangeRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling setProgramAsFree.');
        }


        let headers = this.getDefaultHeaders();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = undefined; // this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = undefined; // this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.getBasePath()}/api/webtools/v1/stream-manager/programs/${encodeURIComponent(String(programId))}/free`,
            body,
            {
                // withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
