import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsGracePeriodPipe, DateTransformPipe, ImageUrlPipe} from './pipes';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoadingComponent} from './loading/loading.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    ImageUrlPipe,
    DateTransformPipe,
    IsGracePeriodPipe,
    LoadingComponent
  ],
  exports: [
    ImageUrlPipe,
    DateTransformPipe,
    IsGracePeriodPipe,
    LoadingComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    };
  }
}
